// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/webpack/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import './add_jquery';
import '../../../node_modules/chart.js/dist/chart';
import 'jquery-ujs';
import Choices from 'choices.js';
import '../src/frontend_edge_charts_configuration';
import '../src/edge_charts';
import '../src/chart_renderer';
import '../src/browser';
import '../src/ie_chart_print';
import '../src/check_form';
import '../src/data_list';
import '../src/main';
import './add_datatable';

window.bootstrap = require('bootstrap/dist/js/bootstrap.js');

$(document).ready(() => {
  
  if ($('[data-csrd-chooser]').text().length > 0) {
    let getValue=  $('[data-csrd-chooser]')[0][0].innerText
    getValue=getValue.substring(6, getValue.length);
    getValue= getValue.slice(0, -1);
    $('.filter-input input').val(getValue);
    $('#fiscal_year_text').text(getValue);
  }  

  $('#close-report-popup').on("click", function() {
    $('#invalid-report-choice').addClass('d-none')
  })

  $('#submitGenReport').on('click',(e) =>{
    let datalists= $('[data-csrd-chooser]')[0].innerText.toLowerCase();
    let year=$('[data-csrd-year-chooser]')[0].innerText.toLowerCase();;
    if(datalists.length == 0 || year.length == 0)

      {
        e.preventDefault();
        $('#invalid-report-choice').removeClass('d-none')
      }
    else {
      $('#invalid-report-choice').addClass('d-none')
    }
  });

  alterClass();
  function alterClass() {
    const getWinSize = window.innerWidth;
    if (getWinSize < 800) {
      $('body').addClass('disabled-overlay');
      alert('Please rotate your device ie, use the landscape mode for optimal display');
    } else {
      $('body').removeClass('disabled-overlay');
    }
  }
  $(window).resize(() => {
    alterClass();
  });

  $('.form-csrd').on('click', function() {
    var clicked= confirm("Please confirm that you want to proceed as this action can’t be undone.");
    if (clicked == false)
    {
      $('.form-csrd').prop('checked', false);
    }
    else {
      var checkboxValue = 1
      var data_listId =  $("#data_list_id").val();
      $.ajax({
      url: "/companyworkspace/data_lists/" + data_listId + "/update_csrd_relevant",
      method: "PATCH",
      data: { is_csrd_relevant: checkboxValue },
      dataType: "json",
      success: function(response) {
        $('#csrd-checkbox').addClass('disabled');
        location.reload();
      }
    });
    }
  })

  $(".single-search-value").on("keyup change",function(){
    $(this).parents('.list-dropdown').find('.select-dropdown-lists-show').addClass('show');
    let input = $(this).val();
    let filter = input.toUpperCase();
    let li = $(this).parents(".list-dropdown").find("li");

    let countDisplayNone=0;

    for (let i = 0; i < li.length; i++) {
        let txtValue = li[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
          countDisplayNone=countDisplayNone+1;
        }
      }
      if(li.length == countDisplayNone)
      {
        $(this).parents('.list-dropdown').find('.select-dropdown-lists-show').removeClass('show');
      }
  });

  // Admin Dashboard filter

  // dashboard on page load

  if ($('.choices-multiple').length > 0) {
    const multipleCancelButton = new Choices('.choices-multiple', {
      removeItemButton: true,
      maxItemCount: -1,
      shouldSort: true,
      position: 'bottom',
      itemSelectText: '',
    });
  }
  if ($('.choices-single').length > 0) {
    const singleSelect = new Choices('.choices-single', {
      removeItemButton: false,
      maxItemCount: -1,
      position: 'bottom',
      itemSelectText: '',
      shouldSort: false,
      shouldSortItems: false,
    });
  }

  showSelectedCheckBoxOnLoad();

  function showSelectedCheckBoxOnLoad() {
    const selectedOrgCheckedDataTag = document.querySelectorAll('.org-checkbox:checked');
    const selectedPeriodCheckedDataTag = document.querySelectorAll('.period-checkbox:checked');
    const selectedOrgGrpCheckedDataTag = document.querySelectorAll('.org-group-checkbox:checked');
    const selectedCountryCheckedDataTag = document.querySelectorAll('.country-checkbox:checked');
    const selectedIndustryCheckedDataTag = document.querySelectorAll('.industry-checkbox:checked');
    const selectedStatusCheckedDataTag = document.querySelectorAll('.status-checkbox:checked');
    const selectedFrameworkCheckedDataTag = document.querySelectorAll('.framework-checkbox:checked');
    const selectedOrgSizeCheckedDataTag = document.querySelectorAll('.org-size-checkbox:checked');
    const selectedDimensionCheckedDataTag = document.querySelectorAll('.dimensions-checkbox:checked');
    showCheckedFilterOptions(selectedOrgCheckedDataTag, 'org', 'Organization', 1);
    showCheckedFilterOptions(selectedPeriodCheckedDataTag, 'period', 'Reference Period', 1);
    showCheckedFilterOptions(selectedOrgGrpCheckedDataTag, 'org-group', 'Organization Group', 1);
    showCheckedFilterOptions(selectedCountryCheckedDataTag, 'country', 'Country', 1);
    showCheckedFilterOptions(selectedIndustryCheckedDataTag, 'industry', 'Industry', 1);
    showCheckedFilterOptions(selectedStatusCheckedDataTag, 'status', 'Status', 1);
    showCheckedFilterOptions(selectedFrameworkCheckedDataTag, 'framework', 'Framework', 1);
    showCheckedFilterOptions(selectedOrgSizeCheckedDataTag, 'org-size', 'Organization Size', 1);
    showCheckedFilterOptions(selectedDimensionCheckedDataTag, 'dimensions', 'Dimensions', 1);
  }

  // listing all checked data when pag loads

  if ($('.category-checkbox').is(':checked')) {
    loadCheckedFilterData('category', 'Survey Question', 'category-checkbox', 1);
  }

  if ($('.level-checkbox').is(':checked')) {
    loadCheckedFilterData('level', 'Level of responsibility', 'level-checkbox', 1);
  }

  if ($('.level-right-checkbox').is(':checked')) {
    loadCheckedFilterData('level-right', 'Level of responsibility', 'level-right-checkbox', 2);
  }

  if ($('.care-checkbox').is(':checked')) {
    loadCheckedFilterData('care', 'Care responsibility', 'care-checkbox', 1);
  }

  if ($('.care-right-checkbox').is(':checked')) {
    loadCheckedFilterData('care-right', 'Care responsibility', 'care-right-checkbox', 2);
  }

  if ($('.race-checkbox').is(':checked')) {
    loadCheckedFilterData('race', 'Race/ethnicity', 'race-checkbox', 1);
  }

  if ($('.race-right-checkbox').is(':checked')) {
    loadCheckedFilterData('race-right', 'Race/ethnicity', 'race-right-checkbox', 2);
  }

  if ($('.age-checkbox').is(':checked')) {
    loadCheckedFilterData('age', 'Age', 'age-checkbox', 1);
  }

  if ($('.age-right-checkbox').is(':checked')) {
    loadCheckedFilterData('age-right', 'Age', 'age-right-checkbox', 2);
  }

  if ($('.lgbtq-checkbox').is(':checked')) {
    loadCheckedFilterData('lgbtq', 'LGBTQ', 'lgbtq-checkbox', 1);
  }

  if ($('.lgbtq-right-checkbox').is(':checked')) {
    loadCheckedFilterData('lgbtq-right', 'LGBTQ', 'lgbtq-right-checkbox', 2);
  }

  if ($('.disability-checkbox').is(':checked')) {
    loadCheckedFilterData('disability', 'Disability', 'disability-checkbox', 1);
  }

  if ($('.disability-right-checkbox').is(':checked')) {
    loadCheckedFilterData('disability-right', 'Disability', 'disability-right-checkbox', 2);
  }

  if ($('.nationality-checkbox').is(':checked')) {
    loadCheckedFilterData('nationality', 'Nationality', 'nationality-checkbox', 1);
  }

  if ($('.nationality-right-checkbox').is(':checked')) {
    loadCheckedFilterData('nationality-right', 'Nationality', 'nationality-right-checkbox', 2);
  }

  if ($('.transgender-checkbox').is(':checked')) {
    loadCheckedFilterData('transgender', 'Transgender', 'transgender-checkbox', 1);
  }

  if ($('.transgender-right-checkbox').is(':checked')) {
    loadCheckedFilterData('transgender-right', 'Transgender', 'transgender-right-checkbox', 2);
  }

  if ($('.view-graph-checkbox').is(':checked')) {
    loadCheckedFilterData('view-graph', 'View Graph', 'view-graph-checkbox', 1);
  }

  if ($('.f1-checkbox').is(':checked')) {
    const filter_name = $('#f1_filter_name').text();
    loadCheckedFilterData('f1', filter_name, 'f1-checkbox', 1);
  }

  if ($('.f2-checkbox').is(':checked')) {
    const filter_name = $('#f2_filter_name').text();
    loadCheckedFilterData('f2', filter_name, 'f2-checkbox', 1);
  }

  // get all checked lists in checkbox dropdown

  function loadCheckedFilterData(whichCategory, whichDropdownName, findCheckedCategoryLists, oneCaseBox) {
    const selectedAllCheckedDataTag = document.querySelectorAll(`.${findCheckedCategoryLists}:checked`);
    showCheckedFilterOptions(selectedAllCheckedDataTag, whichCategory, whichDropdownName, oneCaseBox);
  }

  // dashboard dropdown show/hide functionality

  $('.filter-select-dropdown').on('click', function () {
    showdropdownfilters(this);
  });

  function showdropdownfilters(parameter) {
    if ($(parameter).parents('.filter-dropdown-container').find('.filter-select-content').hasClass('show')) {
      $(parameter).parents('.filter-dropdown-container').find('.filter-select-content').toggleClass('show');
    } else {
      $('.filter-select-content').removeClass('show');
      $(parameter).parents('.filter-dropdown-container').find('.filter-select-content').addClass('show');
    }
  }
  $(document).on('change', '.org-checkbox', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.org-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'org', 'Organization', 1);
  });

  $('.period-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.period-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'period', 'Reference Period', 1);
  });

  $('.org-group-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.org-group-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'org-group', 'Organization Group', 1);
  });

  $('.country-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.country-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'country', 'Country', 1);
  });

  $('.industry-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.industry-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'industry', 'Industry', 1);
  });

  $('.status-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.status-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'status', 'Status', 1);
  });

  $('.framework-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.framework-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'framework', 'Framework', 1);
  });

  $('.org-size-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.org-size-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'org-size', 'Organization Size', 1);
  });

  $('.dimensions-checkbox').on('change', () => {
    const selectedAllCheckedDataTag = document.querySelectorAll('.dimensions-checkbox:checked');
    showCheckedFilterOptions(selectedAllCheckedDataTag, 'dimensions', 'Dimensions', 1);
  });

  // calling each dropdown

  $('.category-checkbox').on('change', () => {
    loadCheckedFilterData('category', 'Survey Question', 'category-checkbox', 1);
  });

  $('.level-checkbox').on('change', () => {
    loadCheckedFilterData('level', 'Level of responsibility', 'level-checkbox', 1);
  });

  $('.level-right-checkbox').on('change', () => {
    loadCheckedFilterData('level-right', 'Level of responsibility', 'level-right-checkbox', 2);
  });

  $('.care-checkbox').on('change', () => {
    loadCheckedFilterData('care', 'Care responsibility', 'care-checkbox', 1);
  });

  $('.care-right-checkbox').on('change', () => {
    loadCheckedFilterData('care-right', 'Care responsibility', 'care-right-checkbox', 2);
  });

  $('.race-checkbox').on('change', () => {
    loadCheckedFilterData('race', 'Race/ethnicity', 'race-checkbox', 1);
  });

  $('.race-right-checkbox').on('change', () => {
    loadCheckedFilterData('race-right', 'Race/ethnicity', 'race-right-checkbox', 2);
  });

  $('.age-checkbox').on('change', () => {
    loadCheckedFilterData('age', 'Age', 'age-checkbox', 1);
  });

  $('.age-right-checkbox').on('change', () => {
    loadCheckedFilterData('age-right', 'Age', 'age-right-checkbox', 2);
  });

  $('.lgbtq-checkbox').on('change', () => {
    loadCheckedFilterData('lgbtq', 'LGBTQ', 'lgbtq-checkbox', 1);
  });

  $('.lgbtq-right-checkbox').on('change', () => {
    loadCheckedFilterData('lgbtq-right', 'LGBTQ', 'lgbtq-right-checkbox', 2);
  });

  $('.disability-checkbox').on('change', () => {
    loadCheckedFilterData('disability', 'Disability', 'disability-checkbox', 1);
  });

  $('.disability-right-checkbox').on('change', () => {
    loadCheckedFilterData('disability-right', 'Disability', 'disability-right-checkbox', 2);
  });

  $('.nationality-checkbox').on('change', () => {
    loadCheckedFilterData('nationality', 'Nationality', 'nationality-checkbox', 1);
  });

  $('.nationality-right-checkbox').on('change', () => {
    loadCheckedFilterData('nationality-right', 'Nationality', 'nationality-right-checkbox', 2);
  });

  $('.transgender-checkbox').on('change', () => {
    loadCheckedFilterData('transgender', 'Transgender', 'transgender-checkbox', 1);
  });

  $('.transgender-right-checkbox').on('change', () => {
    loadCheckedFilterData('transgender-right', 'Transgender', 'transgender-right-checkbox', 2);
  });

  // calling dropdown radio button

  $('.view-graph-checkbox').on('change', () => {
    loadCheckedFilterData('view-graph', 'View Graph', 'view-graph-checkbox', 1);
  });

  $('.f1-checkbox').on('change', () => {
    const filter_name = $('#f1_filter_name').text();
    loadCheckedFilterData('f1', filter_name, 'f1-checkbox', 1);
  });

  $('.f2-checkbox').on('change', () => {
    const filter_name = $('#f2_filter_name').text();
    loadCheckedFilterData('f2', filter_name, 'f2-checkbox', 1);
  });


  // functionality to lists all selected checked dropdown one in div

  function showCheckedFilterOptions(valueChecked, whichdropcheck, groupname, whichSideNumber) {
    let allIdAndValueChecked = {};
    allIdAndValueChecked.Value = [];
    allIdAndValueChecked.ClassName = [];
    for (var i = 0; i < valueChecked.length; i++) {
      allIdAndValueChecked.Value.push(valueChecked[i].dataset.value.replace(/[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';'));
      const whichDropdown = valueChecked[i].className.toLowerCase().indexOf(whichdropcheck);
      const getUniqueClassname = valueChecked[i].className.slice(whichDropdown);
      allIdAndValueChecked.ClassName.push(getUniqueClassname.split(' ')[0]);
    }

    let addTagChecked = '';
    let addTagAllChecked = '';

    $(`.${whichdropcheck}-checkbox-show`).remove();
    for (i = 0; i < allIdAndValueChecked.ClassName.length; i++) {
      addTagChecked = `${addTagChecked}<span id=${allIdAndValueChecked.ClassName[i]} class='font-onezero-light selected-filter'>${allIdAndValueChecked.Value[i]}<a class='close-selected-tag'>&times;</a></span>`;
    }

    addTagAllChecked = `<p class='font-onetwo-semibold selected-filter-boxes${whichSideNumber} ${whichdropcheck}-checkbox-show'> ${groupname} &nbsp;${addTagChecked}</p>`;
    $(`.${whichdropcheck}-checked-data`).append(addTagAllChecked);
    if (allIdAndValueChecked.ClassName.length < 1) {
      $(`.${whichdropcheck}-checkbox-show`).remove();
    }
    allIdAndValueChecked = [];
  }

  // search lists in dropdown

  $('.search-value').on('keyup change', function () {
    $(this).parents('.filter-dropdown-container').find('.filter-select-content').addClass('show');
    const input = $(this).val();
    const filter = input.toUpperCase();
    const li = $(this).parents('.filter-dropdown-container').find('li');
    let i = 0;

    let countDisplayNone = 0;

    for (i = 0; i < li.length; i++) {
      const txtValue = li[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
      } else {
        li[i].style.display = 'none';
        countDisplayNone += 1;
      }
    }


    if (li.length == countDisplayNone) {
      $(this).parents('.filter-dropdown-container').find('.filter-select-content').removeClass('show');
    }
  });

  // equal pay checkbox open functionality

  if ($('.optionShowAdditionaljs').length != 0) {
    if ($('.optionShowAdditionaljs').is(':checked')) {
      $('.estimate-additional').addClass('show');
    }
  }
  $('.optionShowAdditionaljs').on('change', () => {
    if ($('.optionShowAdditionaljs').is(':checked')) {
      $('.estimate-additional').addClass('show');
    }
  });
  $('.optionShowAdditionalNotjs').on('change', () => {
    $('.estimate-additional').removeClass('show');
  });

  // this is for left side menu drop upa and down parent element
  $('.nav-link').on('click', function (event) {
    $('.third-sub-menu-link ').removeClass('show');
    if ($(this).hasClass('show')) {
      $(this).removeClass('show');
    } else {
      $(this).addClass('show');
    }
  });

  // this is for left side menu drop upa and down child element

  $('.third-sub-menu-link').click(function () {
    if ($(this).hasClass('show')) {
      $(this).removeClass('show');
      $('.forth-sub-menu-link ').removeClass('show');
    } else {
      $('.third-sub-menu-link ').removeClass('show');
      $('.forth-sub-menu-link ').removeClass('show');
      $(this).addClass('show');
    }
  });

  $('.nav-arrow').click(function () {
    $(this).parent('.nav-link').toggleClass('show');
    if ($(this).parent('.menu-link-nav').hasClass('third-sub-menu-link')) {
      $(this).parent('.third-sub-menu-link').toggleClass('show');
    }
    if ($(this).parent('.menu-link-nav').hasClass('forth-sub-menu-link')) {
      $(this).parent('.forth-sub-menu-link').toggleClass('show');
    }
    return false;
  });

  $('.forth-sub-menu-link').click(function () {
    if ($(this).hasClass('show')) {
      $(this).removeClass('show');
    } else {
      $('.forth-sub-menu-link ').removeClass('show');
      $(this).addClass('show');
    }
  });

  // allign containers based on year in recert

  let maxHeightOfDivBlock = 0;

  const getclassfromclassLists = $('.div-class-count').map(function () {
    return $(this).attr('class');
  }).get();

  const extractNumberFromArray = [];

  for (let i = 0; i < getclassfromclassLists.length; i++) {
    const s = getclassfromclassLists[i].toString();
    extractNumberFromArray.push(s.match(/ row-compare(\d+)/i)[1]);
  }

  for (let i = 0; i < extractNumberFromArray.length; i++) {
    maxHeightOfDivBlock = Math.max.apply(null, $(`.row-compare${extractNumberFromArray[i]}`).map(function () {
      return $(this).height();
    }).get());

    if (maxHeightOfDivBlock !== 0) {
      $(`.row-compare${extractNumberFromArray[i]}`).css('height', maxHeightOfDivBlock);
    }
  }

  // normal dropdown in results dashboard

  $('.exp-drop').on('click', function () {
    if ($(this).parents('.exp-dropdown-details').hasClass('show')) {
      $(this).parents('.exp-dropdown-details').toggleClass('show');
    } else {
      $('.exp-dropdown-details').removeClass('show');
      $(this).parents('.exp-dropdown-details').addClass('show');
    }
  });

  // show/hide password in login page

  const loginPasswordInput = document.getElementById('login-password-icon');
  if (loginPasswordInput != null) {
    loginPasswordInput.addEventListener('click', () => { showHidePasswordContent('login-password-icon', 'inputPassword'); }, false);
  }
  const loginPasswordIdNew = document.getElementById('login-new-password-img');
  if (loginPasswordIdNew != null) {
    loginPasswordIdNew.addEventListener('click', () => { showHidePasswordContent('login-new-password-img', 'forgetInputPassword'); }, false);
  }
  const loginPasswordIdConfirm = document.getElementById('login-confirm-password-img');
  if (loginPasswordIdConfirm != null) {
    loginPasswordIdConfirm.addEventListener('click', () => { showHidePasswordContent('login-confirm-password-img', 'forgetConfirmInputPassword'); }, false);
  }

  const loginPasswordIdCurrent = document.getElementById('login-current-password-img');
  if (loginPasswordIdCurrent != null) {
    loginPasswordIdCurrent.addEventListener('click', () => { showHidePasswordContent('login-current-password-img', 'forgetCurrentPassword'); }, false);
  }

  function showHidePasswordContent(passwordIconId, inputPasswordId) {
    const x = document.getElementById(inputPasswordId);
    const img = document.getElementById(passwordIconId);
    if (x.getAttribute('type') === 'password') {
      x.type = 'text';
      img.setAttribute('src', '/password-icon-svg.svg');
    } else {
      x.type = 'password';
      img.setAttribute('src', '/password-icon.svg');
    }
  }

  $('[data-toggle="tooltip"]').tooltip({
    animated: 'fade',
    placement: 'bottom',
    html: true,
    delay: 100,
    customClass: 'left-side-tooltip',
  });

  $('[data-toggle="tooltipInputTooltip"]').tooltip({
    placement: 'top',
    customClass: 'input-tooltip',
    boundary: 'window',
    delay: 100,
  });

  $('[data-toggle="largeMoveTooltip"]').tooltip({
      animated: 'fade',
      placement: 'top',
      html: true,
      delay: 100,
      customClass: 'largeMoveTooltip'
  });

  $('[data-toggle="smallMoveTooltipData"]').tooltip({
    animated: 'fade',
    placement: 'top',
    html: true,
    delay: 100,
    customClass: 'small-move-tooltip-data',
  });

  $('body').tooltip({
    delay: 100,
    customClass: 'small-move-tooltip-data',
    selector: '.createdDiv'
  });

  if ($('#v3ChangesModal').length > 0) {
    const layoutLocalStorageKey = 'edgetool.v3_layout_changes_modal.hidden';

    if (!localStorage.getItem(layoutLocalStorageKey)) {
      $('#v3ChangesModal').modal({ show: true }).on('hide.bs.modal', (e) => {
        localStorage.setItem(layoutLocalStorageKey, 'true');
      });
    }
  }
  $(() => {
    $('[data-bs-toggle="tooltip"]').tooltip({ position: { within: 'body' } });
  });

  // scrollbar left position based on page
  // var getOffsetFromActivePagelink = $('.active-sub-menu').parents('.nav-item').offset().top;
  // $(".left-side-scroll").scrollTop(getOffsetFromActivePagelink - 95);

  $('.manage-drop .select-option').on('click', function () {
    const selText = $(this).text();
    $(this).parents('.manage-drop').find('.manage-input').val(selText);
  });

  // var secondElement = new Choices('.choices-single-default', { itemSelectText: '',  searchEnabled: false,
  //  }).setValue();

  if ($('.choices-single-default').length > 0) {
    const singleSelect = new Choices('.choices-single-default', {
      removeItemButton: false,
      maxItemCount: -1,
      position: 'bottom',
      itemSelectText: '',
      shouldSort: false,
      shouldSortItems: false,
    });
  }

  function changeHeaderLabeltoOld() {
    $("#dl-header-text2").text('Information');
    $("#dl-header-text3").text('Comments');
    $("#dl-emp-dt").text('Employee Survey date');
    $("#dl-md-q").text('Use of the mandatory questions');
    $("#dl-aq").text('Added questions');
    $(".add-placeholder-text").attr("placeholder", '');
  }

  function changeHeaderLabeltoNew() {
    $("#dl-header-text2").text('Required Information');
    $("#dl-header-text3").text('Additional Comments');
    $("#dl-emp-dt").text('Employee Survey launch date');
    $("#dl-md-q").text('Added custom variables');
    $("#dl-aq").text('Added custom question');
    $(".add-placeholder-text").attr("placeholder", 'Yes/No');
  }

  $(() => {  
   if ($('[data-data-list-version-chooser]').val() === '6' || $('[data-data-list-version-chooser]').val() === '7') {
      $("#genSurvey input").attr('required', '');
      $('.manage-toggle3').addClass('disabled');
      $('.manage-toggle3 input[type=checkbox]').prop('checked', false);
      $(".manage-dl td.gi-pp").addClass("disabled");
      $(".manage-dl tbody #dl-tg").addClass("d-none");
      changeHeaderLabeltoNew();
    } else if ($('#mg-index').attr('data-edgeplus-enabled') === 'true') {
      $('.manage-toggle3').removeClass('disabled');
    }
    else if($('#mg-index').attr("data-gi-enabled") === 'true')
      {
        $(".manage-dl td.gi").addClass("disabled");    
      }
    else if($('#mg-index').attr("data-edgeplussurvey-enabled") === 'true')
      {    
        $(".manage-dl td.gi-pp").addClass("disabled");
        $(".manage-dl tbody #dl-tg").addClass("d-none");    
        changeHeaderLabeltoNew();
      }
    $('[data-data-list-version-chooser]').change((e) => {
      hideTagBasedOnSelect(parseInt(e.target.value));
    });
    $('.manage-toggle1 input[type=checkbox]').on('change', () => {
      const getCurrentValueSelected = $('[data-data-list-version-chooser]').find('option:first-child').val();
      if (getCurrentValueSelected == 4 && $('.manage-toggle1 input[type=checkbox]').is(':checked')) {
        $('.manage-toggle3').removeClass('disabled');
      } else {
        $('.manage-toggle3').addClass('disabled');
        $('.manage-toggle3 input[type=checkbox]').prop('checked', false);
        $('.manage-dl td input[type=checkbox]').prop('checked', false);
      }
    });


    $('.manage-dl input[type=checkbox]').on('change', () => {
      const getCurrentValueSelected = $('[data-data-list-version-chooser]').find('option:first-child').val();
      if (getCurrentValueSelected == 4) {
        $('.manage-toggle1').removeClass('disabled');
        $('.manage-toggle1 input[type=checkbox]').prop('checked', true);
        $('.manage-toggle3').removeClass('disabled');
      } else if (getCurrentValueSelected == 5 || getCurrentValueSelected == 6 || getCurrentValueSelected == 7) {
        $('.manage-toggle1').removeClass('disabled');
        $('.manage-toggle1 input[type=checkbox]').prop('checked', true);
      }

      if ($('[data-data-list-version-chooser]').length == 0 ) {
        $('.manage-toggle1 input[type=checkbox]').prop('checked', true);
      }
    });
  });

  function hideTagBasedOnSelect(dataValue) {
  if (dataValue === 3) {
    $(".manage-toggle1").addClass("disabled");
    $(".manage-toggle1 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle2").addClass("disabled");
    $(".manage-toggle2 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle3").addClass("disabled");
    $(".manage-toggle3 input[type=checkbox]").prop('checked', false);
    $(".manage-dl td").addClass("disabled");
    $(".manage-dl td input[type=checkbox]").prop('checked', false);
    $(".manage-dl td.gi").addClass("disabled");
    $("#genSurvey").addClass("input-disabled");
    $("#genSurvey input").val('Non-binary');
    $("#genSurvey input").removeAttr('required');
    $(".manage-dl tbody #dl-tg").removeClass("d-none");
    changeHeaderLabeltoOld();
  } else if (dataValue === 4) {
    $(".manage-toggle1").removeClass("disabled");
    $(".manage-toggle1 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle2").addClass("disabled");
    $(".manage-toggle2 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle3").addClass("disabled");
    $(".manage-toggle3 input[type=checkbox]").prop('checked', false);
    $(".manage-dl td").removeClass("disabled");
    $(".manage-dl td input[type=checkbox]").prop('checked', false);
    $(".manage-dl td.gi").addClass("disabled");
    $("#genSurvey").addClass("input-disabled");
    $("#genSurvey input").val('Non-binary');
    $("#genSurvey input").removeAttr('required');
    $(".manage-dl tbody #dl-tg").removeClass("d-none");
    changeHeaderLabeltoOld();
  } else if (dataValue === 5) {
    $(".manage-toggle1").removeClass("disabled");
    $(".manage-toggle1 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle2").removeClass("disabled");
    $(".manage-toggle3").addClass("disabled");
    $(".manage-toggle3 input[type=checkbox]").prop('checked', false);
    $(".manage-dl td").removeClass("disabled");
    $(".manage-dl td input[type=checkbox]").prop('checked', false);
    $(".manage-dl td.gi").addClass("disabled");
    $("#genSurvey").addClass("input-disabled");
    $("#genSurvey input").val('Non-binary');
    $("#genSurvey input").removeAttr('required');
    $(".manage-dl tbody #dl-tg").removeClass("d-none");
    changeHeaderLabeltoOld();
  } else {
    $(".manage-toggle1").removeClass("disabled");
    $(".manage-toggle1 input[type=checkbox]").prop('checked', false);
    $(".manage-toggle2").removeClass("disabled");
    $(".manage-toggle3").addClass("disabled");
    $(".manage-toggle3 input[type=checkbox]").prop('checked', false);
    $(".manage-dl td").removeClass("disabled");
    $(".manage-dl td.gi").removeClass("disabled");
    $(".manage-dl td.gi-pp").addClass("disabled");
    $("#genSurvey").removeClass("input-disabled");
    $("#genSurvey input").attr('required', '');
    $(".manage-dl tbody #dl-tg").addClass("d-none");
    changeHeaderLabeltoNew();
  }
}

});

// file upload functionality

$('.upload-file').on('change', function () {
  const fileName = $(this).val().split('\\').pop();
  $('.show-uploaded-file').addClass('selected').html(fileName);
});


$(document).on('change', '.upload-file-data', function () {
  let fileName = $(this).val().split("\\").pop();
  $(this).parents(':eq(1)').find(".show-uploaded-file").addClass("selected").html(fileName);
});

$(document).on('change', '#reporting_year_select', () => {
  var companyId = $('#csrd_report_company_id').val();
  var dataListYear = $('#reporting_year_select').text();
  $.ajax({
    url: "/ajax/csrd_report_data_list?company_id="+companyId+"&year="+dataListYear,
    method: "GET",
    dataType: "json",
    success: function(response) {
      $('#csrd-data-list .form-floating').empty();
      var str = '<select class="form-control choices-single-default" data-csrd-chooser="true" data-trigger name="csrd_report[data_list_id]" id="csrd_report_data_list_id"></select>';      
      var choices = new Array();
      var selected_year = '';
      if (response) {
        $.each(response, (k, v) => {
          choices.push({value: k, label: v});          
        });
      }
      $('#csrd-data-list .form-floating').append(str);
      const element = document.querySelector('#csrd-data-list select');
      console.log(choices);
      var optChoices = new Choices(element, {
        itemSelectText: "",
        choices: choices
      });
      if (optChoices.getValue() != undefined) {
        const selectedValue = optChoices.getValue().label;
        if (selectedValue != undefined) {
          selected_year = selectedValue;
          selected_year = selected_year.substring(6, selected_year.length);
          selected_year = selected_year.slice(0, -1);    
        }
      }
      $('.filter-input input').val(selected_year);
      $('#fiscal_year_text').text(selected_year);
    }
  });
});

$(document).on('change', '#csrd-data-list select', (e) => {
  let getValue=  e.target.innerText;
  getValue=getValue.substring(6, getValue.length);
  getValue= getValue.slice(0, -1);
  $('.filter-input input').val(getValue);
  $('#fiscal_year_text').text(getValue);
});
